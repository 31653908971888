import React from "react";

import possessionImg from '../../images/services/Possession and Paraphernalia.png';
import ServiceTemplate from "./ServiceTemplate";

const SubItem = [
  {title: 'Got busted with weed?', text: 'It happens, but TicketTamer can help keep your record clean. You will have to pay a fine and court costs, and probably serve some time on probation with some conditions, such as performing some community service or taking a class. But it won’t be too bad, and you really don’t want this on your record.'},
  {title: '', text: `CannaTamer can fix your possession and paraphernalia tickets forhundreds, even thousands, of dollars less than other law firms.  Why? Because we’re trying to help you, not screw you.
  
  CannaTamer can help you get your Missouri medical marijuana card, so you never get that ticket again.
  
  Check out our amazing prices here, including package deals that get you your medical marijuana license for free. 
  
  Then shop and compare. You’ll see why our parent company, TicketTamer, has a 40% referral/repeat client rate, and a 97% success rate. We guarantee it!
  `},
];

const CannaTamer = (props) => {
  return <ServiceTemplate 
  title="CannaTamer" 
  description={`CannaTamer is the only one-stop center for getting and staying legal with canna, at a fair price.`}
  subItem={SubItem}
  url="submit-cannabis-ticket"
  image={possessionImg}
  metaDescription="Helping You Stay Legal - Get your quote instantly - Schedule your free consultation"
  />;
}

export default CannaTamer;
